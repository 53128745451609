/* Include variables for LESS */
:root {
  --rog-background: #fdfdf8;
  --rog-info-focus: #fff;
  --rog-primary-text: #505458;
  --rog-dark-grey: #435966;
  --rog-muted: #aab2bd;
  --rog-success-focus: #00ce55;
  --rog-warning-focus: #ffa000;
  --rog-white: #ffffff;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
/* Import file styles */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&family=League+Spartan:wght@100;300;400;500;700;900&display=swap');
/* General css */
/*s
Version 2.0
[Table of contents]
-------------------------------------------------------------------------
1. Globally Applied Styles
2. Layout/Boxed
3. Hover
4. Support Class
-------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
1. Globally Applied Styles
*/
html,
body {
  position: relative;
  height: 100%;
  background: var(--rog-background);
  color: var(--rog-primary-text);
  font-family: 'Inter', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4285714;
}
#wrapper {
  position: relative;
  top: 0px;
  z-index: 10;
  width: 100%;
  min-height: 100%;
  /*transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);*/
  background: var(--rog-background);
  /* overflow: hidden; */
  transition: transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
}
@media (min-width: 1025px) {
  #wrapper {
    top: 0px !important;
  }
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}
input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
a {
  text-decoration: none !important;
  outline: none;
  color: $primary;
}
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
  outline: none;
  color: $primary;
}
input,
button {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  outline: none;
}
label {
  font-weight: 500;
  margin-bottom: 10px;
}
/* Animation */
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
/* Class */
.fa-hover {
  font-size: 14px;
  line-height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}
.fa-hover .ico,
.fa-hover .fa {
  display: inline-block;
  height: 22px;
  vertical-align: top;
  min-width: 28px;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  color: $dark;
}
.fa-hover:hover {
  background: $primary;
  color: var(--rog-white);
}
.fa-hover:hover .ico,
.fa-hover:hover .fa {
  font-size: 22px;
  line-height: 22px;
  height: 22px;
  color: var(--rog-white);
}
.input-no-style {
  border: none;
  background: none;
  outline: none;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
2. Layout/Boxed
*/
.row-inline-block {
  font-size: 0px;
  text-align: left;
}
.row-inline-block > * {
  display: inline-block;
  float: none;
  font-size: 14px;
  vertical-align: top;
}
.row-inline-block.text-center > * {
  text-align: center;
}
.row.small-spacing {
  margin-left: -10px;
  margin-right: -10px;
}
.row.small-spacing > *[class^='col-'] {
  padding-left: 10px;
  padding-right: 10px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
3. Hover
*/
.light-effect {
  position: relative;
  overflow: hidden;
  display: block;
}
.light-effect:after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: -200%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transform: skew(45deg);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  -o-transform: skew(45deg);
  -ms-transform: skew(45deg);
}
.light-effect:hover:after {
  left: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
4. Support Class
*/
.error {
  color: $danger;
  font-weight: 400;
  padding: 5px 0px 5px 0px;
}
.display-none {
  display: none;
}
@media (min-width: 1025px) {
  .hidden-on-desktop {
    display: none !important;
  }
}
h4 strong {
  font-weight: 600;
  font-size: 16px;
}
.help-block {
  margin-top: 10px;
  color: var(--rog-muted);
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-top-90 {
  margin-top: 90px !important;
}
.margin-top-100 {
  margin-top: 100px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}
.padding-for-mess {
  padding: 10px 15px 10px 15px;
}
.padding-10 {
  padding: 10px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-30 {
  padding: 30px !important;
}
.min-height-100 {
  min-height: 100px !important;
}
.min-height-200 {
  min-height: 200px !important;
}
.min-height-300 {
  min-height: 300px !important;
}
.min-height-400 {
  min-height: 400px !important;
}
.min-height-500 {
  min-height: 500px !important;
}
.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}
.inline-block {
  display: inline-block;
}
.btn-group-lg > .btn,
.btn-lg {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.btn {
  background: $light;
  outline: none !important;
  font-size: 16px;
  line-height: 26px;
  padding: 8px 25px;
  border: none;
  font-weight: 500;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.btn.btn-bordered {
  background: var(--rog-white);
  color: $dark;
  border: 2px solid $light;
}
.btn.btn-bordered:hover {
  background: $light;
}
.btn.btn-rounded {
  border-radius: 23px;
  -moz-border-radius: 23px;
  -webkit-border-radius: 23px;
}
.btn.btn-rounded.btn-lg {
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
}
.btn.btn-rounded.btn-sm {
  border-radius: 19px;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
}
.btn.btn-lg {
  font-size: 18px;
  line-height: 28px;
  padding: 15px 35px;
}
.btn.btn-sm {
  font-size: 14px;
  line-height: 24px;
  padding: 7px 20px;
}
.btn.btn-xs {
  font-size: 12px;
  line-height: 22px;
  padding: 5px 15px;
}
.btn.btn-icon {
  position: relative;
  overflow: hidden;
}
.btn.btn-icon .ico {
  position: absolute;
  top: 0;
  width: 42px;
  height: 100%;
  line-height: 42px;
  background: rgba(0, 0, 0, 0.1);
}
.btn.btn-icon.btn-lg .ico {
  line-height: 58px;
}
.btn.btn-icon.btn-sm .ico {
  line-height: 38px;
  width: 38px;
}
.btn.btn-icon.btn-xs .ico {
  line-height: 32px;
  width: 32px;
}
.btn.btn-icon-left {
  padding-left: 57px;
  padding-right: 15px;
}
.btn.btn-icon-left.btn-lg {
  padding-right: 30px;
  padding-left: 73px;
}
.btn.btn-icon-left .ico {
  left: 0;
}
.btn.btn-icon-left.btn-sm {
  padding-left: 53px;
}
.btn.btn-icon-left.btn-xs {
  padding-left: 47px;
}
.btn.btn-icon-right {
  padding-right: 57px;
  padding-left: 15px;
}
.btn.btn-icon-right.btn-lg {
  padding-left: 30px;
  padding-right: 73px;
}
.btn.btn-icon-right .ico {
  right: 0;
}
.btn.btn-icon-right.btn-sm {
  padding-right: 53px;
}
.btn.btn-icon-right.btn-xs {
  padding-right: 47px;
}
.btn.btn-circle {
  padding-right: 0;
  padding-left: 0;
  width: 42px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn.btn-circle.btn-lg {
  width: 58px;
}
.btn.btn-circle.btn-sm {
  width: 38px;
}
.btn.btn-circle.btn-xs {
  width: 32px;
}
.btn.btn-social {
  width: 38px;
  height: 38px;
  padding: 0;
  line-height: 38px;
  color: var(--rog-white);
}
.btn.btn-facebook {
  background-color: #3b5998 !important;
}
.btn.btn-twitter {
  background-color: #55acee !important;
}
.btn.btn-google-plus {
  background-color: #dd4b39 !important;
}
.btn.btn-linkedin {
  background-color: #007bb6 !important;
}
.btn.btn-instagram {
  background-color: #3f729b !important;
}
.btn.btn-pinterest {
  background-color: #cb2027 !important;
}
.btn.btn-dribbble {
  background-color: #ea4c89 !important;
}
.btn.btn-youtube {
  background-color: #bb0000 !important;
}
.btn.btn-social-with-text {
  width: 100%;
  margin-bottom: 15px;
}
.btn .ico {
  display: inline-block;
}
.btn .ico-left {
  margin-right: 8px;
}
.btn .ico-right {
  margin-left: 8px;
}
.btn-primary {
  background: $primary;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: $primary !important;
}
.btn-primary.btn-bordered {
  border-color: $primary;
  color: $primary;
}
.btn-primary.btn-bordered:hover,
.btn-primary.btn-bordered:active,
.btn-primary.btn-bordered:focus {
  border-color: $primary;
  color: var(--rog-white);
}
.btn-success {
  background: $success;
}
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  background: var(--rog-success-focus) !important;
}
.btn-success.btn-bordered {
  border-color: $success;
  color: $success;
}
.btn-success.btn-bordered:hover,
.btn-success.btn-bordered:active,
.btn-success.btn-bordered:focus {
  border-color: var(--rog-success-focus);
  color: var(--rog-white);
}
.btn-info {
  background: $info;
}
.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background: $info !important;
}
.btn-info.btn-bordered {
  border-color: $info;
  color: $info;
}
.btn-info.btn-bordered:hover,
.btn-info.btn-bordered:active,
.btn-info.btn-bordered:focus {
  border-color: $info;
  color: var(--rog-white);
}
.btn-warning {
  background: $warning;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background: var(--rog-warning-focus) !important;
}
.btn-warning.btn-bordered {
  border-color: $warning;
  color: $warning;
}
.btn-warning.btn-bordered:hover,
.btn-warning.btn-bordered:active,
.btn-warning.btn-bordered:focus {
  border-color: var(--rog-warning-focus);
  color: var(--rog-white);
}
.btn-danger {
  background: $danger;
  color: white;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background: #ff3838 !important;
}
.btn-danger.btn-bordered {
  border-color: $danger;
  color: $danger;
}
.btn-danger.btn-bordered:hover,
.btn-danger.btn-bordered:active,
.btn-danger.btn-bordered:focus {
  border-color: #ff3838;
  color: white !important;
}
.btn-violet {
  background: #7045ff;
  color: var(--rog-white);
}
.btn-violet:hover,
.btn-violet:active,
.btn-violet:focus {
  color: var(--rog-white);
  background: #8445ff !important;
}
.btn-violet.btn-bordered {
  border-color: #7045ff;
  color: #7045ff;
}
.btn-violet.btn-bordered:hover,
.btn-violet.btn-bordered:active,
.btn-violet.btn-bordered:focus {
  border-color: #8445ff;
  color: var(--rog-white);
}
.btn-orange {
  background: #ff460b;
  color: var(--rog-white);
}
.btn-orange:hover,
.btn-orange:active,
.btn-orange:focus {
  color: var(--rog-white);
  background: #ff6230 !important;
}
.btn-orange.btn-bordered {
  border-color: #ff460b;
  color: #ff460b;
}
.btn-orange.btn-bordered:hover,
.btn-orange.btn-bordered:active,
.btn-orange.btn-bordered:focus {
  border-color: #ff6230;
  color: var(--rog-white);
}
.tour-buttons .btn.btn-sm {
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  padding: 0 10px;
}
@media (max-width: 999px) {
  .list-inline-faq li {
    width: 100%;
  }
}
.limit-width {
  max-width: 600px;
  display: block;
  margin: 0px auto 0px;
  float: none;
}
.width-100 {
  max-width: 100px !important;
  width: 100px !important;
}
.width-200 {
  max-width: 200px !important;
  width: 200px !important;
}
.width-300 {
  max-width: 300px !important;
  width: 300px !important;
}
.width-400 {
  max-width: 400px !important;
  width: 400px !important;
}
.width-500 {
  max-width: 500px !important;
  width: 500px !important;
}
/* Background colors */
.bg-custom {
  background-color: #71b6f9 !important;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-success {
  background-color: $success !important;
}
.bg-info {
  background-color: $info !important;
}
.bg-warning {
  background-color: $warning !important;
}
.bg-danger {
  background-color: $danger !important;
}
.bg-muted {
  background-color: #f4f8fb !important;
}
.bg-inverse {
  background-color: #212121 !important;
}
.bg-purple {
  background-color: #5b69bc !important;
}
.bg-pink {
  background-color: #ff8acc !important;
}
.bg-white {
  background-color: var(--rog-white) !important;
}
.bg-lightdark {
  background-color: #f4f8fb !important;
}
.bg-violet {
  background-color: #7045ff !important;
}
.bg-orange {
  background-color: #ff460b !important;
}
/* Text colors */
.text-custom {
  color: #6200ea !important;
}
.text-white {
  color: var(--rog-white) !important;
}
.text-danger {
  color: #ff1744 !important;
}
.text-muted {
  color: var(--rog-muted) !important;
}
.text-primary {
  color: #188ae2 !important;
}
.text-warning {
  color: $warning !important;
}
.text-success {
  color: $success !important;
}
.text-info {
  color: $info !important;
}
.text-inverse {
  color: #212121 !important;
}
.text-pink {
  color: #ff8acc !important;
}
.text-purple {
  color: #5b69bc !important;
}
.text-dark {
  color: #212121 !important;
}
.text-orange {
  color: #ff460b !important;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
  background: $success;
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
  background: $info;
}
.table > tbody > tr.primary > td,
.table > tbody > tr.primary > th,
.table > tbody > tr > td.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr.primary > td,
.table > tfoot > tr.primary > th,
.table > tfoot > tr > td.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > thead > tr.primary > th,
.table > thead > tr > td.primary,
.table > thead > tr > th.primary {
  background: $primary;
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
  background: $danger;
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
  background: $warning;
}
/*---------------------------------------------------------------------*/
/* Header */
/*
Themes: Ninja Admin
Version 2.0
[Table of contents]
-------------------------------------------------------------------------
1. Main Menu
2. Structure
3. Header
4. User Main Menu
5. Navigation
6. Fixed Navbar
-------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
1. Main Menu
*/
.main-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30;
  width: 260px;
  height: 100%;
  padding-top: 185px;
  background: var(--rog-white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
    0 1px 1px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.main-menu .content {
  height: 100%;
  overflow: auto;
}

.main-menu .button-close {
  display: none;
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 16px;
  outline: none;
  background: none;
  border: none;
  font-size: 20px;
  color: var(--rog-white);
}
.main-menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: var(--rog-muted) !important;
}
.main-menu:hover .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.main-content {
  position: relative;
  z-index: 10;
  margin-left: 280px;
  margin-right: 20px;
  padding-top: 95px;
  min-height: 100vh;
  padding-bottom: 75px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
2. Structure
*/
.fixed-navbar {
  position: fixed;
  top: 0px;
  left: 260px;
  z-index: 20;
  right: 0px;
  padding: 0px 20px 0px 80px;
  background: $primary;
  color: var(--rog-white);
}
.fixed-navbar .searchform {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: -3px;
  top: 13px;
  height: 49px;
  font-size: 0;
  line-height: 0;
  padding: 0;
  width: 50px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.fixed-navbar .searchform.active {
  opacity: 1;
  width: 320px;
  visibility: visible;
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
}
.fixed-navbar .searchform .input-search {
  font-weight: 500;
  height: 49px;
  font-size: 14px;
  width: 320px;
  max-width: 100%;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.fixed-navbar .searchform .button-search {
  top: 50%;
  right: 11px;
  color: var(--rog-muted);
  font-size: 18px;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.fixed-navbar .searchform .button-search.mdi {
  font-size: 24px;
}
@media (max-width: 799px) {
  .main-menu {
    transform: translate(-110%, 0);
    -webkit-transform: translate(-110%, 0);
    -moz-transform: translate(-110%, 0);
    -o-transform: translate(-110%, 0);
    -ms-transform: translate(-110%, 0);
  }
  .main-menu .header {
    text-align: left;
    padding-left: 20px;
  }
  .main-menu .button-close {
    display: block;
  }
  .main-content {
    margin-left: 20px;
  }
  .fixed-navbar {
    left: 0px;
    right: 0px;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
  }
  .menu-active .main-menu {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }
}
@media (max-width: 599px) {
  .fixed-navbar .searchform {
    top: 75px;
    position: fixed;
    width: 100%;
    right: 0;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
  }
  .fixed-navbar .searchform.active {
    width: 100%;
  }
  .fixed-navbar .searchform .input-search {
    width: 100%;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
3. Header
*/
.header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 185px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 75px;
  border-bottom: 1px #e6e9ed solid;
}
.header .logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-size: 20px;
  line-height: 75px;
  height: 75px;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  background: $primary;
  font-weight: 500;
}
.header .logo img {
  height: 80%;
  width: auto;
  text-align: center;
}
.header .logo .ico {
  margin-right: 5px;
  font-size: 24px;
  position: relative;
  top: 2px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
4. User Main Menu
*/
.user {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  padding: 32px 40px 0 100px;
  height: 110px;
}
.user:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  clear: both;
  overflow: hidden;
}
.user .control-wrap.active .control-list {
  opacity: 1;
  visibility: visible;
}
.user .avatar {
  max-width: 70px;
  position: absolute;
  top: 19px;
  left: 20px;
}
.user .control-wrap {
  position: absolute;
  width: 20px;
  height: 40px;
  right: 15px;
  top: 44px;
  text-align: center;
}
.user .control-wrap > .fa {
  font-size: 18px;
  line-height: 20px;
  color: #434a54;
  cursor: pointer;
}
.user .control-list {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  z-index: 20;
  width: 200px;
  margin-top: 2px;
  padding: 10px 0px;
  border: 1px solid rgba(152, 166, 173, 0.15);
  background: var(--rog-white);
  list-style: none;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transform: translate(0, 20px);
  -webkit-transform: translate(0, 20px);
  -moz-transform: translate(0, 20px);
  -o-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
.user .control-list a {
  top: 0px;
  color: $dark;
  font-size: 14px;
  line-height: 24px;
  padding: 6px 20px 6px 20px;
  display: block;
  text-align: left;
}
.user .control-list a i {
  font-size: 16px;
  line-height: 24px;
  width: 25px;
}
.user .control-list a:hover {
  color: $primary;
}
.user .name {
  color: $dark;
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user .name a {
  color: $dark;
}
@media (min-width: 1025px) {
  .user .name a:hover {
    color: $primary;
  }
}
.user .position {
  color: var(--rog-muted);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
.user .status {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 12px;
  height: 12px;
  border: 1px solid var(--rog-white);
  background: var(--rog-white);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.user .status:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  background: $success;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
5. Navigation
*/
.navigation .title {
  padding: 12px 20px;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #212121;
  background: #e6e9ed;
}
.navigation .menu {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.navigation .menu a {
  display: block;
  color: #434a54;
}
@media (min-width: 1025px) {
  .navigation .menu a:hover {
    color: $primary;
  }
  .navigation .menu a:hover i {
    color: $primary;
  }
}
.navigation .menu a.active,
.navigation .menu a.current {
  color: $primary;
}
.navigation .menu a i {
  color: #212121;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.navigation .menu > li {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.navigation .menu > li > a {
  position: relative;
  padding: 14px 30px 14px 53px;
  margin: 0px;
}
.navigation .menu > li > a span {
  vertical-align: middle;
  font-size: 14px;
}
.navigation .menu > li > a .menu-arrow {
  position: absolute;
  top: 14px;
  right: 20px;
  font-size: 14px;
  line-height: 18px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.navigation .menu > li.active {
  background: rgba(0, 0, 0, 0.05);
}
.navigation .menu > li.active > a {
  background: #e6e9ed;
}
.navigation .menu > li.active > a .menu-arrow {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
}
.navigation .menu > li > a.current {
  background: $primary;
  color: var(--rog-white);
}
.navigation .menu > li > a.current > i {
  color: var(--rog-white);
}
.navigation .menu > li > a.current .current > a {
  color: $primary;
}
.navigation .menu > a.current .sub-menu {
  display: block;
}
.navigation .menu .sub-menu {
  display: none;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.navigation .menu .sub-menu a {
  padding: 10px 20px 10px 53px;
}
.navigation .menu .menu-icon {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0;
  margin-left: 0;
  width: 50px;
  font-size: 18px;
  line-height: 49px;
  text-align: center;
  vertical-align: middle;
}
.navigation .menu .menu-icon.mdi {
  font-size: 20px;
}
.navigation .menu .notice {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 12px;
}
.navigation .menu .waves-ripple {
  background: -webkit-radial-gradient(
    rgba(113, 182, 249, 0.2) 0,
    rgba(113, 182, 249, 0.3) 40%,
    rgba(113, 182, 249, 0.4) 50%,
    rgba(113, 182, 249, 0.5) 60%,
    rgba(113, 182, 249, 0) 70%
  );
  background: -o-radial-gradient(
    rgba(113, 182, 249, 0.2) 0,
    rgba(113, 182, 249, 0.3) 40%,
    rgba(113, 182, 249, 0.4) 50%,
    rgba(113, 182, 249, 0.5) 60%,
    rgba(113, 182, 249, 0) 70%
  );
  background: -moz-radial-gradient(
    rgba(113, 182, 249, 0.2) 0,
    rgba(113, 182, 249, 0.3) 40%,
    rgba(113, 182, 249, 0.4) 50%,
    rgba(113, 182, 249, 0.5) 60%,
    rgba(113, 182, 249, 0) 70%
  );
  background: radial-gradient(
    rgba(113, 182, 249, 0.2) 0,
    rgba(113, 182, 249, 0.3) 40%,
    rgba(113, 182, 249, 0.4) 50%,
    rgba(113, 182, 249, 0.5) 60%,
    rgba(113, 182, 249, 0) 70%
  );
}
@media (min-width: 800px) {
  .main-menu {
    transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    -webkit-transition: none;
  }
  .header .logo {
    transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    -webkit-transition: none;
  }
  .menu-active .header {
    width: 75px;
    height: 0;
    padding: 0;
  }
  .menu-active .header .logo {
    position: fixed;
    z-index: 988;
    width: 75px;
    height: 75px;
    font-size: 0;
    line-height: 80px;
    color: transparent;
  }
  .menu-active .header .logo img {
    display: none;
  }
  .menu-active .header .logo::first-letter {
    font-size: 28px;
  }
  .menu-active .header .logo:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 75px;
    background: $primary;
    z-index: -2;
  }
  .menu-active .header .logo:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 20px;
    width: 35px;
    height: 35px;
    background-image: url('../images/iso-negative.png');
    z-index: -1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }
  .menu-active .header .logo .ico {
    margin-right: 0;
    top: 0;
  }
  .menu-active .main-menu {
    position: absolute;
    width: 75px;
    padding-top: 75px;
  }
  .menu-active .fixed-navbar {
    left: 75px;
    z-index: 40;
  }
  .menu-active .main-content {
    margin-left: 95px;
  }
  .menu-active .user {
    display: none;
  }
  .menu-active .navigation .title {
    display: none;
  }
  .menu-active .navigation .menu > li {
    position: relative;
  }
  .menu-active .navigation .menu > li > a {
    height: 48px;
    padding: 10px 30px;
    transition: color 0.4s ease;
    -moz-transition: color 0.4s ease;
    -o-transition: color 0.4s ease;
    -ms-transition: color 0.4s ease;
    -webkit-transition: color 0.4s ease;
  }
  .menu-active .navigation .menu > li > a span {
    display: none;
    font-size: 14px;
    line-height: 30px;
  }
  .menu-active .navigation .menu > li > a .menu-arrow,
  .menu-active .navigation .menu > li > a .notice {
    display: none !important;
  }
  .menu-active .navigation .menu > li:hover > a {
    width: 260px;
    background: $primary;
    color: var(--rog-white);
    padding-left: 90px;
  }
  .menu-active .navigation .menu > li:hover > a i {
    color: var(--rog-white);
  }
  .menu-active .navigation .menu > li:hover > a span {
    display: block;
  }
  .menu-active .navigation .menu > li:hover .sub-menu {
    display: block !important;
  }
  .menu-active .navigation .menu .menu-icon {
    font-size: 24px;
    width: 75px;
  }
  .menu-active .navigation .menu .menu-icon.mdi {
    font-size: 26px;
  }
  .menu-active .navigation .menu .sub-menu {
    display: none !important;
    position: absolute;
    top: 100%;
    left: 75px;
    width: 185px;
  }
  .menu-active .navigation .menu .sub-menu a {
    background: #e6e9ed;
    padding: 10px 15px;
  }
  .menu-active .mCSB_container,
  .menu-active .mCustomScrollBox,
  .menu-active .content {
    overflow: visible;
  }
  .menu-active body {
    height: auto;
  }
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
6. Fixed Navbar
*/
.fixed-navbar {
  height: 75px;
}
.fixed-navbar .ico-item {
  display: inline-block;
  position: relative;
  margin-left: 15px;
  cursor: pointer;
  font-size: 19px;
  line-height: 75px;
  color: $secondary;
}
@media (min-width: 1025px) {
  .fixed-navbar .ico-item:hover {
    color: var(--rog-white);
  }
}
.fixed-navbar .ico-item .ico-img {
  max-height: 40px;
  width: auto;
  margin-top: -3px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.fixed-navbar .ico-item .ico-item {
  margin-left: 0;
}
.fixed-navbar .ico-item .ico-item.mdi {
  font-size: 24px;
}
.fixed-navbar .ico-item .ico-item.mdi:before {
  font-size: 24px;
  line-height: 75px;
  position: relative;
  top: 3px;
}
.fixed-navbar .ico-item.mdi {
  font-size: 24px;
}
.fixed-navbar .ico-item.mdi:before {
  font-size: 24px;
  line-height: 75px;
  position: relative;
  top: 3px;
}
.fixed-navbar .ico-item.pulse:before {
  content: '';
  width: 2px;
  height: 2px;
  border: 3px solid #ffce54;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #ffce54;
  z-index: 10;
  position: absolute;
  right: 2px;
  top: 28px;
}
.fixed-navbar .ico-item.pulse:after {
  content: '';
  border: 4px solid #ffce54;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 30px;
  width: 30px;
  -webkit-animation: pulse 3s ease-out;
  -moz-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 16px;
  left: -1px;
  z-index: 1;
  opacity: 0;
}
.fixed-navbar .ico-item.toggle-hover {
  position: relative;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: -10px;
  background: var(--rog-white);
  border: 1px solid rgba(152, 166, 173, 0.15);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transform: translate(0, 20px);
  -webkit-transform: translate(0, 20px);
  -moz-transform: translate(0, 20px);
  -o-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
.fixed-navbar .ico-item.toggle-hover .toggle-content ul {
  margin: 0;
  padding: 10px 15px 0;
  list-style: none;
  font-size: 0;
  line-height: 0;
  text-align: left;
  width: 300px;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content ul li {
  width: calc(33.33333333%);
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content ul a {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: $dark;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content ul a > i {
  display: block;
  font-size: 18px;
  line-height: 28px;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content .read-more {
  display: block;
  background: #f5f5f5;
  color: $dark;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
}
.fixed-navbar .ico-item.toggle-hover .toggle-content .read-more:hover {
  color: $primary;
}
.fixed-navbar .ico-item.toggle-hover.active .toggle-content {
  opacity: 1;
  visibility: visible;
  transform: translate(0);
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
}
.fixed-navbar .ico-item:hover .sub-ico-item {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}
.fixed-navbar .sub-ico-item {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 48px;
  background-color: var(--rog-white);
  padding: 5px 0;
  font-size: 0;
  line-height: 0;
  list-style: none;
  border: 1px solid rgba(152, 166, 173, 0.15);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transform: translate(0, 20px);
  -webkit-transform: translate(0, 20px);
  -moz-transform: translate(0, 20px);
  -o-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
}
.fixed-navbar .sub-ico-item:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 100%;
  top: -10px;
  left: 0;
}
.fixed-navbar .sub-ico-item a {
  display: block;
  width: 200px;
  padding: 6px 20px 6px 20px;
  color: $dark;
  font-size: 14px;
  line-height: 24px;
}
.fixed-navbar .sub-ico-item a:hover {
  color: $primary;
}
/* @media (max-width: 399px) {
  .fixed-navbar .ico-item {
    display: none;
  }
  .fixed-navbar .ico-item:last-child {
    display: inline-block;
  }
} */
/*---------------------------------------------------------------------*/
/* Style */
/*
Themes: Ninja Admin
Version 2.0
[Table of contents]
-------------------------------------------------------------------------
1. Footer
2. Box Content
3. Calendar
4. Chartist Chart
5. Checkbox
6. Data Tables
7. Dropcap
8. Example Content
9. Inbox List
10. Jquery UI
11. Menu Mobile Button
12. Morris Chart
13. Notice
14. noUiSlider
15. Process Bar
16. Radio
17. Search Form
18. Statistics Box
19. Switch
20. Tabs
21. Timepicke
22. Title
23. User Info
24. Widget Stat
25. Widget Stat Chart
26. Misc
27. Mailbox
28. Login, Register, ... Form
29. 404,500 Page
30. Projects Page
31. Task Board
32. Profile
33. Contact List
34. Pricing Plan
35. Invoice
36. Gallery
37. Modal
38. Form Control
39. Reviews Widget
40. Activity Widget
41. Todo Widget
42. X-Editable
43. Sparkline Chart
44. SweetAlert
-------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
1. Footer
*/
.footer {
  padding: 10px 30px 20px 30px;
  margin: 0px -20px 0px -20px;
  color: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 60px;
  bottom: 0;
}
.footer a {
  color: rgba(0, 0, 0, 0.7);
}
.footer small {
  padding-bottom: 20px;
}
.footer .list-inline {
  margin-bottom: 0px;
}
@media (min-width: 1025px) {
  .footer a:hover {
    color: $primary;
  }
}
@media (max-width: 399px) {
  .footer {
    position: relative;
    margin-top: 20px;
  }
  .footer small {
    margin-top: 10px;
  }
  .list-inline .list-inline-item {
    display: block;
    margin-top: 10px;
  }
}

/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
2. Box Content
*/
.box-content {
  position: relative;
  padding: 20px;
  margin: 0px 0px 20px 0px;
  background: var(--rog-white);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
    0 1px 1px rgba(0, 0, 0, 0.05);
  /* State */
  /* Style */
  /* Color */
}
.box-content:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  clear: both;
  overflow: hidden;
}
.box-content .box-title {
  margin: 0px 0px 30px 0px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.box-content .box-title .ico {
  margin-right: 8px;
  line-height: 16px;
}
.box-content .box-title.with-control .controls {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 0px;
  line-height: 16px;
}
.box-content .box-title.with-control .control {
  border: none;
  background: none;
  outline: none;
  height: 16px;
  font-size: 14px;
}
.box-content .box-title.with-control .fa-times {
  position: relative;
  top: -1px;
}
.box-content .content:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  clear: both;
  overflow: hidden;
}
.box-content .dropdown.js__drop_down {
  position: absolute;
  right: 20px;
  top: 20px;
}
.box-content .dropdown.js__drop_down .dropdown-icon {
  color: var(--rog-muted);
}
@media (min-width: 1025px) {
  .box-content .dropdown.js__drop_down .dropdown-icon:hover {
    color: $dark;
  }
}
.box-content .dropdown.js__drop_down .sub-menu {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0px;
  right: 100%;
  margin-right: 5px;
  z-index: 20;
  width: 200px;
  margin-top: 2px;
  padding: 10px 0px;
  border: 1px solid rgba(152, 166, 173, 0.15);
  background: var(--rog-white);
  list-style: none;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transform: translate(0, 20px);
  -webkit-transform: translate(0, 20px);
  -moz-transform: translate(0, 20px);
  -o-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
.box-content .dropdown.js__drop_down .sub-menu a {
  display: block;
  padding: 6px 20px 6px 20px;
  color: $dark;
}
@media (min-width: 1025px) {
  .box-content .dropdown.js__drop_down .sub-menu a:hover {
    color: $primary;
  }
}
.box-content .dropdown.js__drop_down .sub-menu .split {
  margin: 9px 0px 9px 0px;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}
.box-content .dropdown.js__drop_down.active .dropdown-icon {
  color: $dark;
}
.box-content .dropdown.js__drop_down.active .sub-menu {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}
.box-content.card-closed .box-title {
  margin-bottom: 0px !important;
}
.box-content.card-closed .box-title.with-control .fa-minus:before {
  content: '\f067';
}
.box-content.bordered {
  border-top: 4px solid $dark;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.box-content.bordered-all {
  border: 1px solid $dark;
  border-top: 4px solid $dark;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.box-content.card {
  padding: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.box-content.card .box-title {
  margin-bottom: 0px;
  padding: 10px 20px 10px 20px;
  line-height: 30px;
  color: var(--rog-white) !important;
  background-color: $dark;
}
.box-content.card .box-title .ico {
  line-height: 30px;
}
.box-content.card .box-title .controls {
  top: 17px;
}
.box-content.card .dropdown {
  top: 15px;
}
.box-content.card .dropdown .dropdown-icon {
  color: var(--rog-white);
}
.box-content.card .card-content {
  padding: 20px;
}
.box-content.card.bordered-all {
  border-top: none;
}
.box-content.card .dropdown.js__drop_down .dropdown-icon {
  color: var(--rog-white) !important;
}
.box-content.card.white .box-title {
  color: $dark !important;
  background: var(--rog-white);
  border-bottom: 1px solid #e6e9ed;
}
.box-content.primary {
  border-color: #188ae2;
}
.box-content.primary .box-title {
  color: #188ae2;
}
.box-content.success {
  border-color: $success;
}
.box-content.success .box-title {
  color: $success;
}
.box-content.info {
  border-color: $info;
}
.box-content.info .box-title {
  color: $info;
}
.box-content.warning {
  border-color: $warning;
}
.box-content.warning .box-title {
  color: $warning;
}
.box-content.danger {
  border-color: $danger;
}
.box-content.danger .box-title {
  color: $danger;
}
.box-content.muted {
  border-color: #f4f8fb;
}
.box-content.muted .box-title {
  color: #f4f8fb;
}
.box-content.inverse {
  border-color: #212121;
}
.box-content.inverse .box-title {
  color: #212121;
}
.box-content.purple {
  border-color: #5b69bc;
}
.box-content.purple .box-title {
  color: #5b69bc;
}
.box-content.pink {
  border-color: #ff8acc;
}
.box-content.pink .box-title {
  color: #ff8acc;
}
.box-content.lightdark {
  border-color: #f4f8fb;
}
.box-content.lightdark .box-title {
  color: #f4f8fb;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
3. Calendar
*/
.calendar {
  float: left;
  margin-bottom: 0px;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
}
.fc-widget-header,
.fc-widget-content {
  border: 1px solid #d7ecfb;
}
.fc th.fc-widget-header {
  background: #f5f5f5;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px 10px 0px;
  text-transform: uppercase;
}
.fc th.fc-widget-header a {
  color: var(--rog-primary-text);
}
.fc .fc-event .fc-bg {
  display: none;
}
.fc .fc-list-item[class*=' bg-'] {
  color: var(--rog-white);
}
.fc .fc-list-item[class*=' bg-']:hover td {
  background: rgba(255, 255, 255, 0.2);
}
.fc-button {
  background: var(--rog-white);
  border: 1px solid #d7ecfb;
  color: #555;
  text-transform: capitalize;
}
.fc-text-arrow {
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight,
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: var(--rog-white);
}
.fc .fc-event,
#external-events .fc-event {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 12px;
  margin: 5px 7px 5px 7px;
  padding: 5px;
  text-align: center;
}
.external-event {
  color: var(--rog-white);
  cursor: move;
  margin: 10px 0px 10px 0px;
  padding: 6px 10px 6px 10px;
}
.fc-basic-view td.fc-week-number span,
.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}
.fc-toolbar .fc-button-group .fc-button {
  background: var(--rog-white);
}
.fc-toolbar .fc-button-group .fc-button:hover {
  background: #f5f5f5;
}
.fc .fc-event,
.fc .fc-event-dot {
  background: $primary;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}
.fc .fc-event-dot {
  background: var(--rog-white);
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  color: $dark;
}
.fc.fc-ltr .fc-h-event.fc-not-start,
.fc.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 7px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
4. Chartist Chart
*/
.chartist-chart .ct-label {
  font-size: 12px;
}
.chartist-chart-pie .ct-label {
  font-size: 14px;
  color: var(--rog-white);
  fill: var(--rog-white);
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
5. Checkbox
*/
.checkbox input[type='checkbox'] {
  display: none;
}
.checkbox input[type='checkbox']:checked + label:before {
  border-color: #415dfb;
  background: #415dfb;
}
.checkbox input[type='checkbox']:checked + label:after {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.checkbox input[type='checkbox']:disabled + label {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
}
.checkbox label {
  position: relative;
  padding-left: 25px;
}
.checkbox label:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0px;
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  background: var(--rog-white);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.checkbox label:after {
  content: '\f00c';
  font-family: 'FontAwesome';
  font-size: 12px;
  line-height: 15px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 2px;
  color: var(--rog-white);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.checkbox.primary input[type='checkbox']:checked + label:before {
  background: #188ae2;
  border-color: #188ae2;
}
.checkbox.success input[type='checkbox']:checked + label:before {
  background: $success;
  border-color: $success;
}
.checkbox.info input[type='checkbox']:checked + label:before {
  background: $info;
  border-color: $info;
}
.checkbox.warning input[type='checkbox']:checked + label:before {
  background: $warning;
  border-color: $warning;
}
.checkbox.danger input[type='checkbox']:checked + label:before {
  background: $danger;
  border-color: $danger;
}
.checkbox.purple input[type='checkbox']:checked + label:before {
  background: #5b69bc;
  border-color: #5b69bc;
}
.checkbox.pink input[type='checkbox']:checked + label:before {
  background: #ff8acc;
  border-color: #ff8acc;
}
.checkbox.inverse input[type='checkbox']:checked + label:before {
  background: #212121;
  border-color: #212121;
}
.checkbox.circled label:before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
6. Data Tables
*/
.dataTables_wrapper .dataTables_paginate .pagination {
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate .pagination .paginate_button {
  padding: 0px;
  min-width: 0px;
  margin-left: 10px;
  border: none !important;
  background: none !important;
}
.dataTables_wrapper
  .dataTables_paginate
  .pagination
  .paginate_button:first-child {
  margin-left: 0px;
}
.dataTables_wrapper .dataTable {
  margin-top: 10px !important;
  margin-bottom: 18px !important;
}
.dataTables_wrapper .dataTable .group {
  background-color: $primary !important;
  color: var(--rog-white) !important;
}
.dataTables_wrapper .dataTables_scrollBody .dataTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.dataTables_wrapper .dataTables_scrollFoot .dataTable {
  margin-top: 0px !important;
}
.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td,
.dataTables_wrapper table.dataTable thead td {
  border-bottom-color: #ddd;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom-width: 1px;
}
table.focus-on tbody tr.focused th,
table.focus-on tbody tr.focused td {
  background: $primary !important;
  color: var(--rog-white) !important;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
10. Jquery UI
*/
.ui-sortable-placeholder {
  border: 2px dashed $dark;
  background: #ccc;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
11. Menu Mobile Button
*/
.menu-mobile-button {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 75px;
  width: 70px;
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 75px;
  color: #ffffff;
  background: $primary;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
13. Notice
*/
.notice {
  display: inline-block;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  border-radius: 0.25em;
  padding: 2px 6px 0;
  font-size: 75%;
}
.notice-blue {
  color: var(--rog-white);
  background: $primary;
}
.notice-purple {
  color: var(--rog-white);
  background: #5b69bc;
}
.notice-yellow {
  color: var(--rog-white);
  background: $warning;
}
.notice-danger {
  color: var(--rog-white);
  background: #ff1744;
}
/* Notice List */
.notice-list {
  list-style: none;
  padding: 0px;
}
.notice-list li {
  border-bottom: 1px solid #eaedef;
}
.notice-list div {
  display: block;
  position: relative;
  padding: 14px 15px 14px 85px;
  min-height: 75px;
}
.notice-list div:hover {
  background: var(--rog-background);
}
.notice-list .avatar {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 55px;
  height: 55px;
  overflow: hidden;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.notice-list .avatar i {
  font-size: 24px;
  line-height: 55px;
  color: var(--rog-white);
  top: 0px;
}
.notice-list .desc {
  color: #90a4ae;
  font-size: 13px;
  line-height: 23px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
}
.notice-list .name {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #212121;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
}
@media (min-width: 1025px) {
  .notice-list .name:hover {
    color: $primary;
  }
}
.notice-list .time {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: var(--rog-muted);
  position: absolute;
  right: 20px;
  top: 17px;
}
.notice-list .points {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: var(--rog-muted);
  position: absolute;
  right: 20px;
  top: 40px;
}
.notice-list span.loading {
  text-align: center;
  display: block;
}
/* Notice Popup */
.notice-popup {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 75px;
  right: 20px;
  z-index: 40;
  background: var(--rog-white);
  width: 450px;
  max-width: 100%;
  padding-top: 50px;
  transform: translate(0, 30px);
  -webkit-transform: translate(0, 30px);
  -moz-transform: translate(0, 30px);
  -o-transform: translate(0, 30px);
  -ms-transform: translate(0, 30px);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
@media (max-width: 550px) {
  .notice-popup {
    width: 100%;
    right: 0;
  }
  .notice-popup#message-popup {
    right: 0;
  }
}
.notice-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}
.notice-popup .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: var(--rog-muted) !important;
}
.notice-popup:hover .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.notice-popup .content {
  overflow: auto;
  height: 100%;
}
.notice-popup .content .notice-list {
  width: 100%;
  overflow: hidden;
}
.notice-popup .popup-title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  padding: 11px 10px 11px 18px;
  color: #1b1f20;
  margin: 0px;
  border-bottom: 1px solid #e6e9ed;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 27px;
}
.notice-popup .popup-title a {
  text-transform: initial;
  font-weight: 400;
}
.notice-popup .popup-close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  font-size: 18px;
  line-height: 59px;
  padding: 0px 10px 0px 10px;
  color: $dark;
}
@media (min-width: 1025px) {
  .notice-popup .popup-close:hover {
    color: $danger;
  }
}
.notice-popup .notice-read-more {
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  padding: 3px 10px 13px;
  text-align: center;
  display: block;
}
.notice-popup .notice-read-more:hover {
  color: $primary;
}
#message-popup {
  right: 60px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
15. Process Bar
*/
.process-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  margin-top: 12px;
  background: var(--rog-white);
  overflow: hidden;
}
.process-bar .bar {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 5px;
  z-index: 2;
}
.process-bar .bar-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  z-index: 1;
  opacity: 0.2;
  width: 100%;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
16. Radio
*/
.radio input[type='radio'] {
  display: none;
}
.radio input[type='radio']:checked + label:before {
  border-color: $dark;
}
.radio input[type='radio']:checked + label:after {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.radio input[type='radio']:disabled + label {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
}
.radio label {
  position: relative;
  padding-left: 25px;
}
.radio label:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0px;
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.radio label:after {
  content: '';
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  top: 4px;
  left: 3px;
  width: 11px;
  height: 11px;
  background: $dark;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.radio.primary label:after {
  background: $primary;
}
.radio.success label:after {
  background: $success;
}
.radio.info label:after {
  background: $info;
}
.radio.warning label:after {
  background: $warning;
}
.radio.danger label:after {
  background: $danger;
}
.radio.purple label:after {
  background: #5b69bc;
}
.radio.pink label:after {
  background: #ff8acc;
}
.radio.inverse label:after {
  background: #212121;
}
.radio.primary input[type='radio']:checked + label:before {
  border-color: $primary;
}
.radio.success input[type='radio']:checked + label:before {
  border-color: $success;
}
.radio.info input[type='radio']:checked + label:before {
  border-color: $info;
}
.radio.warning input[type='radio']:checked + label:before {
  border-color: $warning;
}
.radio.danger input[type='radio']:checked + label:before {
  border-color: $danger;
}
.radio.purple input[type='radio']:checked + label:before {
  order-color: #5b69bc;
}
.radio.pink input[type='radio']:checked + label:before {
  border-color: #ff8acc;
}
.radio.inverse input[type='radio']:checked + label:before {
  border-color: #212121;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
17. Search Form
*/
.searchform {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  padding: 13px 0px 13px 0px;
}
.searchform .input-search {
  border: none;
  width: 190px;
  height: 34px;
  padding: 7px 40px 7px 20px;
  font-size: 13px;
  line-height: 20px;
  color: var(--rog-primary-text);
  background: var(--rog-white);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.searchform .input-search::-webkit-input-placeholder {
  color: #999999;
}
.searchform .input-search:-moz-input-placeholder {
  color: #999999;
}
.searchform .input-search::-moz-input-placeholder {
  color: #999999;
}
.searchform .input-search:-ms-input-placeholder {
  color: #999999;
}
.searchform .button-search {
  position: absolute;
  top: 22px;
  right: 10px;
  color: $dark;
  z-index: 10;
  background: none;
  outline: none;
  border: none;
}
@media (min-width: 1025px) {
  .searchform .button-search:hover {
    color: $primary;
  }
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
18. Statistics Box
*/
.statistics-box {
  padding-top: 14px;
  min-height: 80px;
  text-align: center;
}
.statistics-box.with-icon {
  position: relative;
  padding-left: 80px;
  text-align: right;
}
.statistics-box.with-icon .ico {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  font-size: 70px;
  line-height: 80px;
  text-align: center;
}
.statistics-box.with-icon .ico.small {
  font-size: 42px;
}
.statistics-box .counter {
  margin-top: 0px;
  margin-bottom: 0px;
}
.statistics-box .text {
  color: var(--rog-muted);
  margin: 0px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
19. Switch
*/
.switch {
  display: block;
  margin-bottom: 10px;
}
.switch input[type='checkbox'] {
  display: none;
}
.switch input[type='checkbox']:checked + label:before {
  background: $dark;
  border-color: $dark;
}
.switch input[type='checkbox']:checked + label:after {
  left: 14px;
}
.switch label {
  position: relative;
  padding-left: 40px;
  font-weight: 400;
  cursor: pointer;
  margin: 0px;
  min-height: 20px;
}
.switch label:before {
  content: '';
  width: 30px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0px;
  border: 1px solid #ddd;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #f1f1f1;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.switch label:after {
  content: '';
  width: 16px;
  height: 16px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  left: 0px;
  z-index: 10;
  background: var(--rog-white);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.switch.primary input[type='checkbox']:checked + label:before {
  background: $primary;
  border-color: $primary;
}
.switch.success input[type='checkbox']:checked + label:before {
  background: $success;
  border-color: $success;
}
.switch.info input[type='checkbox']:checked + label:before {
  background: $info;
  border-color: $info;
}
.switch.warning input[type='checkbox']:checked + label:before {
  background: $warning;
  border-color: $warning;
}
.switch.danger input[type='checkbox']:checked + label:before {
  background: $danger;
  border-color: $danger;
}
.switch.purple input[type='checkbox']:checked + label:before {
  background: #5b69bc;
  border-color: #5b69bc;
}
.switch.pink input[type='checkbox']:checked + label:before {
  background: #ff8acc;
  border-color: #ff8acc;
}
.switch.inverse input[type='checkbox']:checked + label:before {
  background: #212121;
  border-color: #212121;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
20. Tabs
*/
.tab-content {
  padding: 15px;
  border: 1px solid #ddd;
  border-top: none;
}
.tab-header {
  padding: 15px;
  border: 1px solid #ddd;
}
.tab-header .navbar {
  margin-bottom: 0px;
  border: none;
  min-height: auto;
}
.tab-header.pill {
  border: none;
  padding: 0px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
21. Timepicke
*/
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #188ae2;
  text-decoration: none;
}
.bootstrap-timepicker-widget table td input {
  border: none;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
22. Title
*/
.page-title {
  display: inline-block;
  margin: 0px;
  font-size: 18px;
  line-height: 75px;
  font-weight: 500;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
23. User Info
*/
.user-info .avatar {
  float: left;
  width: 78px;
}
.user-info .avatar img {
  border: 3px solid var(--rog-white);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}
.user-info .name,
.user-info p,
.user-info .text-custom {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-info .text-custom {
  font-weight: 500;
}
.user-info .name {
  margin: 0px 0px 5px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.user-info .right-content {
  margin-left: 90px;
}
.user-info p {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 5px;
}
.user-info a {
  color: $primary;
  font-size: 13px;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
26. Misc
*/
.form-inline .checkbox label,
.form-inline .radio label {
  padding-left: 25px;
}
.label {
  padding: 0.2em 0.6em 0.2em;
  font-weight: 400;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 9px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 12px;
  }
}
.ui-accordion .ui-accordion-header {
  outline: none;
}
.list-group-item,
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0px;
  border-color: rgba(120, 130, 140, 0.13);
}
.table-purchases a {
  color: #ccc;
}
.table-purchases a:hover {
  color: #999;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
27. Mailbox
*/
.box {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background: var(--rog-white);
  border: 1px solid #e1e3e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}
.box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0px;
}
.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box .border-right {
  border-right: 1px solid #f4f4f4;
}
.box .border-left {
  border-left: 1px solid #f4f4f4;
}
.box.box-solid {
  border-top: none;
}
.box.box-solid > .box-header .btn.btn-default {
  background: none;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
.box.box-solid.box-default > .box-header {
  color: #444;
  background-color: #d2d6de;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: #444;
}
.box.box-solid.box-primary {
  border: 1px solid $primary;
}
.box.box-solid.box-primary > .box-header {
  color: var(--rog-white);
  background-color: $primary;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-success {
  border: 1px solid $success;
}
.box.box-solid.box-success > .box-header {
  color: var(--rog-white);
  background-color: $success;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-info {
  border: 1px solid $info;
}
.box.box-solid.box-info > .box-header {
  color: var(--rog-white);
  background-color: $info;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-warning {
  border: 1px solid $warning;
}
.box.box-solid.box-warning > .box-header {
  color: var(--rog-white);
  background-color: $warning;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-danger {
  border: 1px solid $danger;
}
.box.box-solid.box-danger > .box-header {
  color: var(--rog-white);
  background-color: $danger;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-purple {
  border: 1px solid #5b69bc;
}
.box.box-solid.box-purple > .box-header {
  color: var(--rog-white);
  background-color: #5b69bc;
}
.box.box-solid.box-purple > .box-header a,
.box.box-solid.box-purple > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-pink {
  border: 1px solid #ff8acc;
}
.box.box-solid.box-pink > .box-header {
  color: var(--rog-white);
  background-color: #ff8acc;
}
.box.box-solid.box-pink > .box-header a,
.box.box-solid.box-pink > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid.box-inverse {
  border: 1px solid #212121;
}
.box.box-solid.box-inverse > .box-header {
  color: var(--rog-white);
  background-color: #212121;
}
.box.box-solid.box-inverse > .box-header a,
.box.box-solid.box-inverse > .box-header .btn {
  color: var(--rog-white);
}
.box.box-solid[class*='bg'] > .box-header {
  color: var(--rog-white);
}
.box > .box-header > .box-tools .btn {
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.box .box-header:before,
.box .box-body:before,
.box .box-footer:before,
.box .box-header:after,
.box .box-body:after,
.box .box-footer:after {
  content: '';
  display: table;
}
.box .box-header:after,
.box .box-body:after,
.box .box-footer:after {
  clear: both;
}
.box .box-header {
  display: block;
  position: relative;
  padding: 14px 20px;
  color: #444;
}
.box .box-header.with-border {
  border-bottom: 1px solid #f2f4f6;
}
.box .box-header > .fa,
.box .box-header > .glyphicon,
.box .box-header .box-title,
.box .box-header .ico {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  margin: 0px;
  margin-right: 5px;
  font-weight: 500;
}
.box .box-header > .box-tools {
  position: absolute;
  top: 7px;
  right: 20px;
}
.box .box-header > .box-tools [data-toggle='tooltip'] {
  position: relative;
}
.box .box-header > .box-tools.pull-right .dropdown-menu {
  right: 0px;
  left: auto;
}
.box .box-header .has-feedback .form-control {
  width: 300px;
  height: 35px;
}
.box .box-header .has-feedback .form-control-feedback {
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #656d78;
}
.box.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.btn-mail-main {
  height: 50px;
  font-size: 14px;
  line-height: 34px;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: none;
  color: #97a0b3;
}
.btn-box-tool.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}
.box-body {
  padding: 0px 20px;
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.box-body > .table {
  margin-bottom: 0px;
}
.box-body .nav li.active a {
  background: var(--rog-white);
  color: $primary;
}
.box-body .nav a {
  padding: 13px 0;
  color: #212121;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
}
.box-body .nav a:hover {
  background: var(--rog-white);
  color: $primary;
}
.box-body .nav .fa {
  display: inline-block;
  width: 35px;
  line-height: 26px;
  font-size: 17px;
}
.box-body .nav .label-text-right {
  color: #656d78;
  font-size: 14px;
  line-height: 25px;
}
.box-body .nav .label-right {
  padding: 0px 7px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
  padding-top: 2px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  font-weight: 400;
}
.box-footer {
  padding: 10px 20px;
  background: var(--rog-white);
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.mailbox-messages .table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0px 1px;
}
.mailbox-messages .table > tbody > tr > td {
  border-top: none;
}
.mailbox-messages .table .mailbox-subject {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 500px;
  overflow: hidden;
}
.mailbox-messages .mailbox-star a {
  color: #ccd1d9;
  font-size: 17px;
}
.mailbox-messages .mailbox-name a {
  color: #212121;
  font-weight: 500;
}
.mailbox-messages .mailbox-subject {
  color: #90a4ae;
}
.mailbox-messages .mailbox-subject span {
  color: #656d78;
  font-weight: 500;
}
.mailbox-messages .table-striped > tbody > tr:nth-of-type(odd) {
  background: var(--rog-background);
}
.mailbox-messages .unread > td:first-of-type {
  border-left: 5px solid $primary;
}
.mailbox-messages .unread .mailbox-subject span {
  color: #212121;
}
.mailbox-messages tr > td:first-of-type {
  border-left: 5px solid transparent;
}
.mailbox-read-info {
  border-bottom: 1px solid #f4f4f4;
  padding: 10px 20px;
}
.mailbox-read-info h3 {
  font-size: 20px;
  margin: 0px;
}
.mailbox-read-info h5 {
  padding: 5px 0px 0px 0px;
  margin: 0px;
}
.mailbox-read-time {
  color: #999;
  font-size: 13px;
}
.mailbox-controls {
  border-bottom: 1px solid #f4f4f4;
  padding: 8px 20px 1px 15px;
}
.mailbox-controls:after {
  content: '';
  display: block;
  clear: both;
}
.mailbox-controls .btn {
  padding: 0;
  line-height: 35px;
  height: 35px;
  min-width: 80px;
  margin: 0 5px 7px;
  font-size: 18px;
  color: #656d78;
  background: var(--rog-background);
  border-color: #ccd1d9;
}
.mailbox-controls .btn-group {
  font-size: 0;
}
.mailbox-controls .btn-group .btn {
  min-width: 40px;
  color: var(--rog-muted);
  font-size: 26px;
  background: var(--rog-background);
  border: 1px solid #ccd1d9;
  margin: 0 !important;
  z-index: 1 !important;
}
.mailbox-controls .btn-group .btn:first-child {
  border-right: none;
}
.mailbox-controls .inbox-text {
  display: inline-block;
  font-size: 16px;
  line-height: 35px;
  margin-right: 8px;
}
@media (max-width: 929px) {
  .mailbox-controls .pull-right {
    width: 100%;
    padding: 10px;
  }
}
.icheckbox_square-blue {
  transform: scale(0.7);
}
.mailbox-read-message {
  padding: 10px 20px;
}
.mailbox-footer {
  padding: 10px 20px;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
}
.mailbox-attachments {
  list-style: none;
  padding: 0px;
}
.mailbox-attachments li {
  float: left;
  width: 200px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  margin-right: 10px;
}
.mailbox-attachment-name {
  font-weight: 700;
  color: #666;
}
.mailbox-attachment-icon,
.mailbox-attachment-info,
.mailbox-attachment-size {
  display: block;
}
.mailbox-attachment-info {
  padding: 10px;
  background: #f4f4f4;
}
.mailbox-attachment-size {
  color: #999;
  font-size: 12px;
}
.mailbox-attachment-icon {
  text-align: center;
  font-size: 65px;
  color: #666;
  padding: 20px 10px 20px 10px;
}
.mailbox-attachment-icon.has-img {
  padding: 0px;
}
.mailbox-attachment-icon.has-img > img {
  max-width: 100%;
  height: auto;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
28. Login, Register, ... Form
*/
#single-wrapper {
  min-height: 100vh;
  background: $gradient-success;
  overflow: hidden;
  width: 100%;
  padding: 0px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-iso {
  width: 40%;
  min-width: 120px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.frm-single {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: -40px auto 0 auto;
}
.frm-single .inside {
  background: $primary;
  position: relative;
  padding: 20px;
  z-index: 20;
  border-radius: 25px;
  box-shadow: 0 0 1px 0 #ccc;
  -webkit-box-shadow: 0 0 1px 0 #ccc;
  -moz-box-shadow: 0 0 1px 0 #ccc;
}
.frm-single .title {
  margin-bottom: 5px;
  font-family: 'League Spartan', sans-serif;
  font-size: 20px;
  line-height: 34px;
  white-space: nowrap;
  color: $dark;
  text-align: center;
}
.frm-single .a-link {
  color: #999;
}
.frm-single .a-link:hover {
  color: $dark;
}
.frm-single .frm-title {
  color: #999;
  text-align: center;
  font-size: 16px;
  padding-bottom: 20px;
}
.frm-single .frm-input {
  display: block;
  position: relative;
  margin-bottom: 20px;
}
.frm-single .frm-input .frm-inp {
  width: 100%;
  height: 40px;
  padding: 0px;
  padding-left: 30px;
  border: 1px solid #ccc;
  font-size: 14px;
  line-height: 38px;
  border-left: 3px solid #999;
}
.frm-single .frm-input .frm-inp:focus {
  border-color: $primary;
  padding-left: 10px;
}
.frm-single .frm-input .frm-inp:focus + .frm-ico {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
}
.frm-single .frm-input .frm-ico {
  position: absolute;
  top: 0px;
  left: 3px;
  width: 30px;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #999;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.frm-single .checkbox {
  margin: 0px;
}
.frm-single .a-link i {
  margin-right: 5px;
}
.frm-single .frm-submit {
  display: block;
  width: 100%;
  height: 36px;
  border: none;
  background: $secondary;
  color: $primary;
  margin-bottom: 15px;
}
@media (min-width: 1025px) {
  .frm-single .frm-submit:hover {
    // background: $gradient-success;
  }
}
.frm-single .frm-submit i {
  margin-left: 5px;
}
.frm-single .frm-footer {
  padding: 20px 0px 0px 0px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
  color: #999;
}
.frm-single .avatar {
  display: block;
  margin: 0px auto 20px;
  float: none;
  position: relative;
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
}
.frm-single .ico-email {
  display: block;
  margin: 0px auto 20px;
  float: none;
  width: 80px;
  height: 80px;
}
.frm-single .txt-login-with {
  text-align: center;
  margin-bottom: 15px;
  color: #999;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
29. 404,500 Page
*/
#page-404 {
  height: 100%;
  background: url('../images/404.jpg') top center repeat;
  background-position: center center;
  background-attachment: fixed;
  overflow: hidden;
  width: 100%;
  padding: 0px 15px 0px 15px;
}
#page-404 .content {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0px auto 0px;
  float: none;
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
#page-404 p {
  padding: 30px 0px 30px 0px;
  color: #777;
  font-size: 16px;
  line-height: 30px;
  margin: 0px;
}
@media (max-height: 359px) {
  #page-404 {
    min-height: 100%;
    height: auto;
  }
  #page-404 .content {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    top: 0px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.title-on-desktop {
  position: relative;
}
.title-on-desktop .title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.title-on-mobile {
  display: none;
}
@media (max-width: 649px) {
  .title-on-desktop {
    display: none;
  }
  .title-on-mobile {
    display: block;
  }
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
30. Projects Page
*/
.prj-header {
  position: relative;
  min-height: 64px;
  padding: 0 130px;
  border-bottom: 1px solid #e8e8e8;
}
.prj-header .btn-submit-prj {
  position: absolute;
  top: 10px;
  right: 0px;
}
.prj-header .result-count {
  position: absolute;
  top: 10px;
  left: 0px;
  font-size: 14px;
  line-height: 34px;
}
.prj-header .filters {
  list-style: none;
  padding: 0px;
  font-size: 0px;
  text-align: center;
  margin-bottom: 0px;
}
.prj-header .filters li {
  display: inline-block;
  font-size: 14px;
  line-height: 34px;
  margin: 0px 5px 0px 5px;
}
.prj-header .filters a {
  display: block;
  color: $dark;
  position: relative;
  padding: 10px 10px 20px;
}
.prj-header .filters a:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0px;
  background: #188ae2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.prj-header .filters a:hover,
.prj-header .filters a.active {
  color: #188ae2;
}
.prj-header .filters a:hover:before,
.prj-header .filters a.active:before {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
@media (max-width: 999px) {
  .prj-header {
    padding: 65px 0 0;
  }
}
.prj-item {
  display: block;
  position: relative;
  background: var(--rog-white);
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.prj-item .top-project-section {
  height: 220px;
  padding: 40px 20px 20px 20px;
  border-bottom: 1px solid #e9e9e9;
}
.prj-item .top-project-section h3 {
  margin: 20px 0px 0px 0px;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
}
.prj-item .top-project-section .meta {
  font-size: 13px;
  color: #b2b2b2;
}
.prj-item .top-project-section .meta span {
  color: #767676;
}
.prj-item .project-icon {
  height: 90px;
}
.prj-item .project-icon img {
  display: block;
  margin: 0px auto 0px;
  float: none;
  max-width: 80px;
  height: auto;
}
.prj-item .bottom-project-section {
  padding: 15px 20px 15px 20px;
  font-size: 13px;
  color: #767676;
}
.prj-item .bottom-project-section:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  clear: both;
  overflow: hidden;
}
.prj-item .bottom-project-section .points {
  float: left;
  margin-right: 10px;
}
.prj-item .bottom-project-section .views {
  float: left;
  margin-right: 10px;
}
.prj-item .bottom-project-section .feedable-time {
  float: right;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
31. Task Board
*/
.task-item {
  display: block;
  margin-bottom: 20px;
  padding: 10px 15px 10px 15px;
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.task-item .title {
  font-weight: 600;
  color: $dark;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
@media (min-width: 1025px) {
  .task-item .title:hover {
    color: #188ae2;
  }
}
.task-item .metas {
  padding-top: 5px;
  color: #bbb;
}
.task-item .metas:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  clear: both;
  overflow: hidden;
}
.task-item .metas .meta {
  margin-right: 10px;
  float: left;
}
.task-item .metas .ico {
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  margin-right: 4px;
}
.task-item .progress {
  margin-bottom: 5px;
  height: 10px;
  margin-top: 10px;
}
.task-item .task-icon {
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 5px;
}
.task-user {
  margin-bottom: 20px;
  position: relative;
  top: 0;
  left: 0;
  width: auto;
}
.task-user .name {
  margin-bottom: 5px;
}
.user-assign-list li {
  vertical-align: top;
}
.user-assign-list .avatar {
  display: block;
  width: 34px;
  height: 34px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.user-assign-list .add_new {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.user-attach-list .thumbs {
  display: block;
  padding: 5px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.user-attach-list .add_new {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.frm-comment {
  position: relative;
  padding: 10px 0px 10px 55px;
}
.frm-comment .avatar {
  position: absolute;
  top: 4px;
  left: 0px;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
32. Profile
*/
.profile-avatar .btn {
  margin: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  color: var(--rog-white);
}
.profile-avatar .btn-friend {
  background: $info;
}
.profile-avatar .btn-inbox {
  background: $primary;
}
.profile-friends-list li {
  margin-bottom: 10px;
}
.profile-friends-list li .avatar {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  display: block;
}
.dot-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.dot-list li {
  margin-bottom: 15px;
  padding: 0px 0px 0px 30px;
  position: relative;
}
.dot-list li:before {
  content: '\f10c';
  font-family: 'FontAwesome';
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 14px;
  line-height: 20px;
}
.dot-list .date {
  font-size: 12px;
  color: #939ba2;
  display: block;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
33. Contact List
*/
.box-contact {
  position: relative;
  margin-top: 60px;
  padding: 60px 20px 20px 20px;
  border: 1px solid #e0e0e0;
  background: var(--rog-white);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 30px;
}
.box-contact .avatar {
  width: 108px;
  height: 108px;
  position: absolute;
  top: -54px;
  border: 4px solid #e7e6e6;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.box-contact .name {
  font-size: 24px;
  margin-bottom: 0px;
}
.box-contact .job {
  font-size: 16px;
  color: #a8a8a8;
  font-weight: 700;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 20px;
}
.box-contact .job:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 75px;
  height: 2px;
  background: $warning;
}
.box-contact p {
  font-size: 14px;
  line-height: 22px;
}
.contact-social a {
  display: block;
  font-size: 20px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: var(--rog-white);
}
.contact-social .fa-phone {
  background: $info;
}
.contact-social .fa-envelope {
  background: $success;
}
.contact-social .fa-facebook {
  background: #3b5999;
}
.contact-social .fa-twitter {
  background: #55acee;
}
.contact-social .fa-google-plus {
  background: #dd4b39;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
37. Modal
*/
.modal-content {
  padding: 0 15px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.modal-dialog {
  max-width: 100%;
}
/*---------------------------------------------------------------------*/
/*-----------------------------------------------------------------------
38. Form Control
*/
.form-control {
  line-height: 24px;
  padding: 9px 14px;
  height: 45px;
  border-color: #ccd1d9;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.form-control.input-sm {
  padding: 0 10px;
  height: 30px;
}
.form-control:focus {
  border-color: $info !important;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12) !important;
}
textarea.form-control {
  height: 105px;
}
.has-success .form-control {
  border-color: $success;
}
.has-warning .form-control {
  border-color: $warning;
}
.has-error .form-control {
  border-color: $danger;
}
.has-inverse .form-control {
  border-color: $dark;
}
.form-with-icon {
  position: relative;
}
.form-with-icon .item-icon-right {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.form-with-icon .form-control {
  padding-right: 50px;
}
.has-success .form-with-icon .item-icon {
  color: $success;
}
.has-warning .form-with-icon .item-icon {
  color: $warning;
}
.has-error .form-with-icon .item-icon {
  color: $danger;
}
.has-inverse .form-with-icon .item-icon {
  color: $dark;
}
.input-group-btn > .btn {
  min-width: 50px;
  padding: 7px 10px;
  font-size: 18px;
  line-height: 29px;
  color: #656d78;
  border: 1px solid #ccd1d9;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 45px;
}
.input-group-btn > .btn.no-border {
  border: none;
  line-height: 31px;
}
.input-group-btn > .btn span {
  font-size: 14px;
}

/* MODALS */
.ReactModalPortal > div {
  z-index: 9999;
}

.open-modal-image {
  cursor: pointer;
  margin-right: 10px;
}

.modal-content-image {
  max-height: 80vh;
}

.modal-dots {
  bottom: 10px !important;
}

.modal-dots.slick-dots li.slick-active button::before {
  color: $primary !important;
}

.modal-dots.slick-dots li button::before {
  font-size: 8px;
}

/* DATE PICKER */
.datepick {
  border: none;
  width: 100%;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper > div {
  width: 100%;
}

/* DRAG AND DROP FILE UPLOADER */

.form-control.image-active {
  height: 118px;
}

.form-control.image-active > section,
.form-control.image-active > section > div {
  height: 100px;
}

.img-file {
  max-height: 100px;
}

/* FIXES TO BTN-SM */
.btn-sm {
  cursor: pointer;
  border: none;
}

/* SWEET ALERTS */
.logout-alert .swal-footer,
.logout-alert .swal-text {
  text-align: center;
}

.logout-alert .swal-button--confirm {
  background-color: rgb(246, 14, 14);
}

.logout-alert .swal-button--confirm:hover {
  background-color: rgb(204, 0, 0);
}

.logout-alert .swal-button--confirm:focus {
  box-shadow: none;
}

/* ERROR HANDLING */
.danger {
  color: red;
}

/* BRAND ASSETS */
.brand-assets a {
  line-height: 14px !important;
}

.brand-assets i {
  margin-bottom: 10px;
}

.brand-assets span.txt {
  font-size: 11px;
  line-height: 12px !important;
}

/* OS Specific Colors */

.android-bg {
  background-color: #a4c639;
}

.apple-bg {
  background-color: #a5adb0;
}

.bluetooth-bg {
  background-color: #3b5998;
}

.android-color {
  color: #a4c639;
}

.apple-color {
  color: #a5adb0;
}

.bluetooth-color {
  color: #3b5998;
}

.capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.no-wrap {
  white-space: normal;
}

ul.profile-list {
  padding-left: 5px;
}

ul.profile-list li {
  list-style: none;
}

ul.profile-list li i {
  margin-bottom: 15px;
}

ul.profile-list li span.icon {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.app-build {
  color: #90a4ae;
  font-size: 12px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
}

.admin-pic-placeholder {
  display: flex;
}

.admin-pic-placeholder i {
  position: absolute;
  width: 100%;
  color: var(--rog-white);
  text-align: center;
  font-size: 2em;
}

.user-pic-placeholder {
  position: relative;
  width: 100%;
}

.user-pic-placeholder:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.user-pic-placeholder i {
  position: absolute;
  width: 100%;
  color: var(--rog-white);
  text-align: center;
  font-size: 8em;
}

/* ANIMATED BACKGROUND */

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: var(--rog-white);
  font-size: 50px;
}

.area {
  // background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles.rog li {
  position: absolute;
  display: block;
  list-style: none;
  width: 45px;
  height: 45px;
  background-image: url('../images/iso-negative.png');
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.bg-transition {
  transition: 200ms all;
}

/* DRAG N DROP CONTAINER */
.btn-dragable {
  outline: none !important;
  font-size: 16px;
  line-height: 26px;
  padding: 8px 25px;
  border: none;
  font-weight: 500;
  box-shadow: none!important;
  -moz-box-shadow: none!important;
  -webkit-box-shadow: none!important;
}

.btn-dragable.btn-circle {
  padding-right: 0;
  padding-left: 0;
  width: 42px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.image-drag-container {
  border-radius: 15px;
  background-color: transparent;
  position: relative;
  max-width: 20%;
  min-width: 120px;
}

.image-drag-container button {
  position: absolute;
  top: 0;
  right: 0;
}

.image-drag-container img {
  border-radius: 15px;
}

.trumbowyg-dropdown-emoji {
  max-height: 25vh;
  overflow: scroll;
}

/* LOADER TRANSITIONS */

/* LOADER */
body.body-loading {
  overflow: hidden;
}

.loader {
  width: 100vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.typing_loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  top: 49vh;
  margin: auto;
  position: relative;
  left: -12px;
}
@-webkit-keyframes typing {
  0% {
    background-color: rgba(90, 226, 116, 1);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  25% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  75% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 1);
  }
}

@-moz-keyframes typing {
  0% {
    background-color: rgba(90, 226, 116, 1);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  25% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  75% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 1);
  }
}

@keyframes typing {
  0% {
    background-color: rgba(90, 226, 116, 1);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  25% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 2),
      24px 0px 0px 0px rgba(90, 226, 116, 0.2);
  }
  75% {
    background-color: rgba(90, 226, 116, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(90, 226, 116, 0.2),
      24px 0px 0px 0px rgba(90, 226, 116, 1);
  }
}

.floating-button {
  position: absolute;
  top: -10px;
  right: -10px;
}

.actions-cell {
  overflow: visible !important;
}

hr.split {
  margin: 9px 0px 9px 0px;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.profile-upload {
  bottom: 15px !important;
  right: 15px !important;
  transition: transform 0.5s linear;
}

.profile-upload .btn.btn-circle {
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}

.profile-upload .btn.btn-circle:hover {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  50%,
  90% {
    transform: rotate(-30deg);
  }

  20%,
  40%,
  60%,
  80% {
    transform: rotate(0deg);
  }

  30%,
  70% {
    transform: rotate(30deg);
  }
}

.profile-placeholder {
  min-height: 300px;
  font-size: 72px;
}

.sidebar-profile {
  width: 70px;
  height: 70px;
  border: 3px solid $warning;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  background-size: cover;
}

/* CURSOR TYPES */

button:disabled {
  cursor: not-allowed;
}

/* TABLE IMAGES */

.promo-avatar {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.sticky-action-panel {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
}

/* SIDEBAR LOGS */
.sidebar-logs {
  padding: 40px;
  min-width: 20vw;
}
.sidebar-logs .logs {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.swal-react {
  z-index: 9999 !important;
  overflow: visible !important;
}

.modal-body #react-select-2-listbox {
  position: relative;
  z-index: 9999;
}

.google-maps {
  border-radius: 5px;
}

.admin-pic-placeholder span.initials {
  position: absolute;
  width: 100%;
  font-family: 'League Spartan';
  color: #ffffff;
  text-align: center;
  font-size: 2em;
  margin-top: 3px;
  font-weight: 700;
}

/* LOOM EMBED */
.loom-container {
  position: relative;
  padding-bottom: 50%;
  width: 70vw;
  min-width: 100%;
}

.loom-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-gradient-primary {
  background: $gradient-primary;
}

.bg-gradient-secondary {
  background: $gradient-secondary;
}

.bg-gradient-warning {
  background: $gradient-warning;
}

.bg-gradient-success {
  background: $gradient-success;
}

.box-content.rounded {
  border-radius: 15px !important;
}

.btn.btn-secondary {
  color: $primary !important;
  background-color: $secondary;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

label {
  margin-top: 15px;
  margin-bottom: 5px;
}

input,
select {
  background-color: white !important;
}

.fonticon-container {
  > .fonticon-wrap {
    float: left;
    width: 60px;
    height: 60px;
    line-height: 1rem;
    text-align: center;
    // border: 2px solid $primary;
    border-radius: 0.1875rem;
    margin-right: 0;
    margin-bottom: 1.5rem;
    cursor: pointer;
    > svg {
      transition: all 0.2s ease-in-out;
      &:hover {
        stroke: $primary;
        transform: scale(1.3);
      }
    }
    &.youtube {
      width: 100px;
    }
  }
  &:hover {
    svg {
      color: $primary;
      transform: scale(1.3);
    }
  }

  > .fonticon-classname,
  > .fonticon-unit {
    display: block;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  > .fonticon-unit {
    font-size: 1rem;
    font-style: italic;
  }
}
