/* change the colors */
$primary: #272B30;
$secondary: #EDC769;
$accent: #5AE274;
$danger: #E8433C;
$success: 	#2DC963;
$warning: #F3D03E;
$info: #0C569B;
$light: #e2e2e2;
$dark: #303642;

$body-bg: #ffffff;

/* Gradients */
$gradient-primary: linear-gradient(193.44deg, rgba(12, 86, 155, 0.8) 10.98%, rgba(5, 159, 255, 0.8) 91.84%);
$gradient-secondary: linear-gradient(192.54deg, #303642 6.46%, #B5C7DD 89.63%);
$gradient-warning: linear-gradient(195.41deg, #FF7150 10.67%, #F3D03E 89.2%);
$gradient-success: linear-gradient(195.41deg, #EDC769 10.67%, #272B30 87.56%);

@import "bootstrap";
@import "./custom.scss";
@import "./dropzone.scss";